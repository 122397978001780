@font-face {
  font-family: 'RaleGrotesk';
  src: url('./fonts/RaleGrotesk-Regular.woff2') format('woff2');
  font-display: swap;
  font-weight: 100 400;
}

@font-face {
  font-family: 'RaleGrotesk';
  src: url('./fonts/RaleGrotesk-Medium.woff2') format('woff2');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'RaleGrotesk';
  src: url('./fonts/RaleGrotesk-Bold.woff2') format('woff2');
  font-display: swap;
  font-weight: 600 950;
}

@font-face {
  font-family: 'RaleGroteskBase';
  font-weight: 200;
  font-style: normal;
  src: url('./fonts/Novozymes-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'RaleGroteskBase';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/RaleGrotesk-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'RaleGroteskBase';
  font-weight: 500;
  font-style: normal;
  src: url('./fonts/RaleGrotesk-Bold.woff2') format('woff2');
}
